
  import { Setting } from '@/types'
  import { defineComponent, PropType, ref, Ref } from 'vue'
  export default defineComponent({
    props: {
      setting: { type: Object as PropType<Setting>, required: true },
    },
    methods: {
      click(index: number) {
        console.log(
          `${this.setting.title} has been changed to "${this.setting.options[index]}"`,
        )
        this.selecting = false
        this.setting.select(index)
      },
    },
    setup() {
      return {
        selecting: ref(false),
      }
    },
  })
