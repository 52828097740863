
  import { defineComponent } from 'vue'
  import Config from '@/components/Config.vue'
  import Meta from '@/components/Meta.vue'

  export default defineComponent({
    components: {
      Config,
      Meta,
    },
    provide() {
      return {
        reload: this.reload,
      }
    },
    methods: {
      reload() {
        console.log('Reloading')
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      },
    },
    data() {
      return {
        isRouterAlive: true,
      }
    },
  })
