
  import { defineComponent } from 'vue'
  import Panel from '@/components/Panel.vue' // @ is an alias to /src

  export default defineComponent({
    name: 'Home',
    components: {
      Panel,
    },
  })
