
  import { defineComponent, computed, ref, PropType, inject } from 'vue'
  import { WordStatus } from '@/types'
  import { GlobalStatus } from '@/constants'
  import { translatePinyin } from '@/utils/pinyin'
  import { TableType } from './Panel.vue'
  import { ConfigStore, GlobalStore } from './Config.vue'

  export default defineComponent({
    props: {
      table: { type: Object as PropType<TableType>, required: true },
      currentIndex: { type: Number, default: 0 },
    },
    setup(props) {
      const global = inject('global') as GlobalStore
      const config = computed(() => (inject('config') as ConfigStore).getState())
      const itemRefs = ref([] as Array<HTMLElement>)
      const pushRef = (el: any) => itemRefs.value.push(el)
      const current = computed(() => {
        const currentItem = props.table[props.currentIndex]
        return {
          word: currentItem.word,
          pinyin: currentItem.pinyin,
        }
      })
      const currentPage = computed(() =>
        props.table.slice(0, config.value.perPage),
      )

      return {
        config,
        global,
        GlobalStatus,
        WordStatus,
        current,
        currentPage,
        pushRef,
        translatePinyin,
      }
    },
  })
